

import { reactive, Ref, ref, onMounted, watchEffect, computed } from "vue";
import { grupo, planilhas } from "@/services/LancamentoMensalWorkFlow";
import { ElConfigProvider } from "element-plus";
import ptBr from 'element-plus/lib/locale/lang/pt-br';
import { ElMessage } from 'element-plus'
import moment from "moment";
import useAlert from "@/composables/Alert";
import { useStore } from "vuex";
import { useRouter } from "vue-router";


interface RuleForm {
  grupo: '',
  mes: '',
  ano: '',
  dia: ''
}
export default {
  components: { ElConfigProvider },
  setup() {
    const { showTimeAlert } = useAlert();
    const store = useStore()
    const optionsGrupo = ref()
    const ruleFormRef = ref()
    const uploadProduto = ref()
    const uploadPacotes = ref()
    const uploadOsAberta = ref()
    const router = useRouter()
    const usuarioLogado = computed(() => store.getters.usuarioLogado);
    const loadingGrupoList = ref(false)
    const erroFormatFile = reactive({
      produto: false,
      pacote: false,
      osAberta: false
    })
    const loadingGrupo = ref(true)
    const ruleForm = reactive<RuleForm>({
      grupo: '',
      mes: '',
      ano: '',
      dia: ''
    })
    const formDataExcel = reactive({
      pacotes: null as any,
      produto: null as any,
      osAberta: null as any
    })
    const messageErro = reactive({
      existErro: false,
      message: '',
    })

    const selectedDay = reactive({
      max: 0
    });

    const rules = reactive({
      grupo: [
        { required: true, message: 'Selecione um grupo', trigger: 'blur' },
      ],
      mes: [
        { required: true, message: 'Selecione o mês', trigger: 'blur' },
      ],
      ano: [
        { required: true, message: 'Selecione o ano', trigger: 'blur' },
      ],
      dia: [
        { validator: validaDia, trigger: 'blur' },
      ]
    })

    //watchEffect para a logica dos dias referente ao mes selecionado
    watchEffect(() => {
      const mes = moment(ruleForm.mes, 'ddd MMM DD YYYY HH:mm:ss ZZ')
      const ano = moment(ruleForm.ano, 'ddd MMM DD YYYY HH:mm:ss ZZ')

      const lastDayOfMonth = new Date(Number(ano.format('YYYY')), parseInt(mes.format('MM')), 0).getDate();

      selectedDay.max = 0;
      selectedDay.max = lastDayOfMonth;
    });

    // Essa função é chamada sempre que o dia selecionado mudar
    watchEffect(() => {
      if (selectedDay.max > selectedDay.max) {
        selectedDay.max = selectedDay.max;
      }
    });

    const submitForm = async (formEl) => {
      if (!formEl) return
      await formEl.validate(async (valid, fields) => {
        if (valid) {
          //verifica se os tres arquivos foi inserido
          if (uploadPacotes.value.uploadFiles[0] && uploadProduto.value.uploadFiles[0] && uploadOsAberta.value.uploadFiles[0]) {
            messageErro.message = ''
            const formdata = new FormData();

            const mes = moment(ruleForm.mes, 'ddd MMM DD YYYY HH:mm:ss ZZ')
            const ano = moment(ruleForm.ano, 'ddd MMM DD YYYY HH:mm:ss ZZ')

            formdata.append("fileVendasProdutos", formDataExcel.produto, "fileVendasProdutos.xlsx");
            formdata.append("fileOsAbertas", formDataExcel.osAberta, "fileOsAbertas.xlsx");
            formdata.append("filePacotes", formDataExcel.pacotes, "filePacotes.xlsx");
            formdata.append("ano", ano.format('YYYY'));
            formdata.append("mes", mes.format('MM'));
            formdata.append("dia", ruleForm.dia);
            formdata.append("codGrupo", ruleForm.grupo);
            formdata.append("userId", usuarioLogado.value.codUsuario);

            document.body.classList.add("page-loading");

            await planilhas(formdata).then(() => {

              document.body.classList.remove("page-loading");
              messageErro.message = 'Importação concluída com êxito'

              showTimeAlert("Importação concluída com êxito")
              messageErro.existErro = false

            }).catch(err => {

              document.body.classList.remove("page-loading");

              if (err.response.data.erro) {
                showTimeAlert(err.response.data.message, "error");
                messageErro.existErro = true
                messageErro.message = err.response.data.message
              } else {
                showTimeAlert("Não foi possível completar a solicitação", "error");
              }
            })


          } else {
            ElMessage.error('Você deve fazer o upload de exatamente três arquivos.')
          }
        } else {
          console.log('error submit!', fields)
        }
      })
    }

    const getGrupo = async () => {
      loadingGrupoList.value = true
      const response = await grupo()
      optionsGrupo.value = response
      loadingGrupoList.value = false

    }


    //funçoes para pegar o file e validar o tipo do arquivo
    const handleFileProduto = (response, file, fileList) => {
      const formData = new FormData();
      formData.append("file", file[0].raw);
      if (!response.name.includes(".xlsx")) {
        ElMessage.error('Formato inválido. Apenas arquivos no formato XLSX podem ser inseridos.')
        uploadProduto.value.clearFiles(); // limpar o campo
        erroFormatFile.produto = true
      } else {
        formDataExcel.produto = file[0].raw
        erroFormatFile.produto = false
      }
    }

    const handleFilePacotes = (response, file, fileList) => {
      const formData = new FormData();
      formData.append("file", file[0].raw);

      if (!response.name.includes(".xlsx")) {
        uploadPacotes.value.clearFiles(); // limpar o campo
        ElMessage.error('Formato inválido. Apenas arquivos no formato XLSX podem ser inseridos.'); // Corrigi a mensagem de erro, estava como JPG format
        erroFormatFile.pacote = true;
      } else {
        erroFormatFile.pacote = false;

        formDataExcel.pacotes = file[0].raw;
      }
    }

    const handleFileOsAbertas = (response, file, fileList) => {
      const formData = new FormData();
      formData.append("file", file[0].raw);
      if (!response.name.includes(".xlsx")) {
        uploadOsAberta.value.clearFiles(); // limpar o campo
        ElMessage.error('Formato inválido. Apenas arquivos no formato XLSX podem ser inseridos.')
        erroFormatFile.osAberta = true
      } else {
        erroFormatFile.osAberta = false
        formDataExcel.osAberta = file[0].raw
      }
    }

    // essas funçoes e para a logica de colocar um file e substituir o que estava selecionado
    const handleExceedProduto = (files) => {
      uploadProduto.value!.clearFiles()
      const file = files[0]
      uploadProduto.value!.handleStart(file)
    }

    const handleExceedPacotes = (files) => {
      uploadPacotes.value!.clearFiles()
      const file = files[0]
      uploadPacotes.value!.handleStart(file)
    }

    const handleExceedOsAberta = (files) => {
      uploadOsAberta.value!.clearFiles()
      const file = files[0]
      uploadOsAberta.value!.handleStart(file)
    }

    function validaDia(rule: any, dia: any, callback: any) {
      if (ruleForm.mes == '' || ruleForm.mes == null) {
        callback(new Error('Selecione o mês'))

        return false
      }

      if (ruleForm.ano == '' || ruleForm.ano == null) {
        callback(new Error('Selecione o ano'))

        return false
      }

      if (dia == '') {
        callback(new Error('Dia Obrigatorio'))

        return false
      }


      if (Number(ruleForm.dia) <= selectedDay.max) {

        selectedDay.max = selectedDay.max;

        return true
      } else {
        callback(new Error('O dia inserido é maior do que o número de dias que este mês possui. Por favor, insira um dia válido.'))
        return false
      }
    }

    onMounted(() => {
      getGrupo()
    })

    return {
      ruleForm,
      rules,
      submitForm,
      optionsGrupo,
      ruleFormRef,
      ptBr,
      handleFileProduto,
      handleFileOsAbertas,
      handleFilePacotes,
      erroFormatFile,
      uploadProduto,
      uploadOsAberta,
      uploadPacotes,
      handleExceedProduto,
      handleExceedPacotes,
      handleExceedOsAberta,
      loadingGrupoList,
      messageErro
    }
  }
}
