import ApiService from "@/services/ApiService";
import {GruposGet} from "@/models/LancamentoMensalWorkflowModel";

const grupo = async () : Promise<GruposGet> => {
    const { data } = await ApiService.get(`/vendas/importacao-workflow/list-grupo`);
    return data;
};

const planilhas = async (payload)  => {
    const { data } = await ApiService.post(`/vendas/importacao-workflow`,payload);
    return data;
};

export {grupo,planilhas}
